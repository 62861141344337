<template>
	<div class="mb-5" id="pageTop">
		<div class="row mb-5">
			<div class="col-12 col-md-5">
				<ul class="list-group list-group-flush my-3 sticky-top">
					<li class="list-group-item" align="center">
						<img v-if="userProfile.imgFile" class="border border-0 border-primary img-fluid rounded" :src="userProfile.imgFile" />
						<i v-else style="font-size:6rem;" class="bi bi-person-circle"></i>
					</li>
				</ul>
			</div>
			<div class="col-12 col-md-7 my-2 p-3">
				<div class="h5 mb-4" style="text-transform: capitalize;">
					My Account - <strong>{{ userProfile.fname }} {{ userProfile.lname }}</strong>
				</div>
				<ul class="list-group mb-4">
					<li class="list-group-item">
						<div class="row my-2 pointer">
							<div class="col-2" align="center">
								<i class="bi bi-person"></i>
							</div>
							<div class="col">
								Manage my profile
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row my-2 pointer">
							<div class="col-2" align="center">
								<i class="bi bi-bell"></i>
							</div>
							<div class="col">
								Notifications
							</div>
							<div class="col-auto" align="center">
								<span class="badge rounded-pill bg-secondary"> 0 </span>
							</div>
						</div>
					</li>
					<li class="list-group-item" @click="$router.push('/reset-password')">
						<div class="row my-2 pointer">
							<div class="col-2" align="center">
								<i class="bi bi-lock"></i>
							</div>
							<div class="col">
								Reset password
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row my-2 pointer" @click="btnLogoutClick">
							<div class="col-2" align="center">
								<i class="bi bi-box-arrow-right"></i>
							</div>
							<div class="col">
								Log out
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row my-2 pointer" @click="$store.dispatch('openExternalLink', { url: 'https://socaislands.com/frequently-asked-questions' })">
							<div class="col-2" align="center">
								<i class="bi bi-question-circle"></i>
							</div>
							<div class="col">
								Help
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<div class="row my-2 pointer" @click="$store.dispatch('openExternalLink', { url:'https://socaislands.com/about-us' })">
							<div class="col-2" align="center">
								<i class="bi bi-info-circle"></i>
							</div>
							<div class="col">
								About Soca Islands
							</div>
						</div>
					</li>
				</ul>
				<div class="h5">
					&nbsp;
				</div>
				<div class="col text-secondary small mb-5">
					Version 1.0
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Profile",
	computed:{
		userProfile(){
			return this.$store.state.userProfile;
		},
		user(){
			return this.$store.state.userProfile;
		},
		crewfinderCount(){
			const crewfinder = this.$store.state.crewfinder;
			if(Array.isArray(crewfinder)){
				return (crewfinder.filter((item) => item.author.id == this.userProfile.id)).length;
			}else{
				return 0;
			}
		},
		marketplaceCount(){
			const marketplace =  this.$store.state.marketplace;
			if(Array.isArray(marketplace)){
				return (marketplace.filter((item) => item.author.id == this.userProfile.id)).length;
			}else{
				return 0;
			}
		},
		msgCount(){
			const messages = this.$store.state.messages || [];
			const filter = (msg)=>{
				return !msg.read.includes(this.userProfile.id)
			}
			return messages.filter(filter).length;
		},
	},
	data(){
		return{
			isBusy: false,
			profile:{},
			isPhoneValid:true,
			privateData:{},
		}
	},
	methods:{
		btnLogoutClick(){
			this.$store.dispatch("logOut");
		},
	},

	mounted(){
		this.$store.commit("setPageTitle", "Profile")
	}

}
</script>
